
import { defineComponent, createVNode } from "vue";
import Base from "@/components/template/Base.vue";
import CarryOut from "@/components/template/CarryOut.vue";
import Done from "@/components/template/Done.vue";
import {
  getDetailsApp,
  getPlatformMode,
  // updateMode,
  // addMode,
  // copyMode,
} from "@/api/template/api.js";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    Base,
    CarryOut,
    Done,
    ExclamationCircleOutlined,
  },
  data() {
    return {
      noBottomBtn: false,
      current: 1, //当前步骤条
      currentDone: 1, //已完成到第几步
      //从路由传过来的id获取到的本条数据
      platform: {
        taskPackage: {
          taskSub: [
            {
              collectItems: [],
            },
          ],
        },
      },
      formState: {
        taskPackage: {
          taskSub: [
            {
              collectItems: [],
            },
          ],
        },
      },
      keepFormState: JSON.stringify({
        taskPackage: {
          taskSub: [
            {
              collectItems: [],
            },
          ],
        },
      }),
    };
  },
  methods: {
    async getData(id: any = false) {
      if (!id) {
        id = this.$route.params.id;
      }
      let res: any = await getDetailsApp(id);
      if (
        res.data.data.taskPackage === "" ||
        res.data.data.taskPackage === null
      ) {
        let id =res.data.data.id;//租户模板id必存在
        res.data.data = {
          id:id,
          taskPackage: {
            taskSub: [
              {
                collectItems: [],
              },
            ],
          },
        };
      }
      this.formState = res.data.data;
      this.keepFormState = JSON.stringify(res.data.data);
      (this as any).$store.commit("template/templateFormState", this.formState);
      if (res.data.data.taskTypeCode == null) {
        this.currentDone = 0;
      } else {
        this.currentDone = 1;
        // } else if (res.data.data.taskStatus != "编辑中") {
        //   this.currentDone = 2;
      }
      if (this.$route.path.includes("/details")) {
        this.currentDone = 2;
      }
      console.log(this.currentDone);
    },
    // 点击完成
    save() {
      (this as any).$message.success("保存成功");
      this.getData();
      // let back: any = this.$route.query.back;
      // this.$router.go(back ? back : -1);
    },
    cancel() {
      // let back: any = this.$route.query.back;
      // this.$router.go(back ? back : -1);
    },
    toCreated() {
      (this as any).$router.push(`/templateApp/created/new?back=-2`);
    },
  },
  mounted() {
    this.getData();
    getPlatformMode().then((res: any) => {
      if (
        res.data.data.taskPackage === "" ||
        res.data.data.taskPackage === null
      ) {
        res.data.data = {
          taskPackage: {
            taskSub: [
              {
                collectItems: [],
              },
            ],
          },
        };
      }
      this.platform = res.data.data;
      // if (this.$route.params.id != "new") {
      // } else {
      // (this as any).$store.commit("template/templateFormState", this.formState);
      // }
    });
  },
});
